import React, { useState, useRef, useEffect } from 'react';
import './Chat.css';

const { Configuration, OpenAIApi } = require("openai");


const Chat = ({speakers, selectedSpeaker}) => {
    const [messages, setMessages] = useState([]);
    const [video, setVideo] = useState('/assets/loading.mp4');
    const inputRef = useRef();
    const apiUrl = 'https://api.d-id.com/talks';
    const authorizationKey = 'b2Zpci5vQGZpcmUtYXJjLmNvbQ:J7yGBH2ypuyU9rd0ooRI1';
    let greetMe = true;
    let selectedBot;
    for (let i = 0; i < speakers.length; i++) {
        if(speakers[i].id == selectedSpeaker) {
            selectedBot = speakers[i]
        }
    }

    const fetchVideo = async(videoId) => {
        let data;
        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json',
                authorization: `Basic ${authorizationKey}`
            }
        };

        while(true) {
            console.log("while")
            try {
                const response = await fetch(`${apiUrl}/${videoId}`, options);
                data = await response.json();
                if(data && data.status == "done") {
                    console.log(data)
                    break;
                }
                
            } catch (err) {
                console.error(err);
                throw err; // You can choose to re-throw the error or return a default value in case of an error.
            }
        }
        return data
    }

    const fetchVideoID = async (sentence) => {
        let phrase = (sentence == 'greecing') ? selectedBot.greecing : sentence

        try {
            let payload = { "script": {
                    "type": "text",
                    "provider": {
                        "type": selectedBot.provider,
                        "voice_id": selectedBot.voice
                    },
                    "input": phrase
                },
                "source_url": selectedBot.image_url
            }
            
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Basic ${authorizationKey}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
      
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            
            const data = await response.json();
            return data.id;
        } catch (error) {
            console.error('Error fetching data from API:', error);
            return null;
        }
    };
 
    const sendMessage = async () => {
        try {
            setVideo('/assets/loading.mp4')
            let input = inputRef.current.value;
            setMessages((prevMessages) => [...prevMessages, { text: input, sender: 'user' }]);
            const completedSentence = await getIA(input);
            setMessages((prevMessages) => [...prevMessages, { text: completedSentence, sender: 'bot' }]);
            inputRef.current.value = ''
            const videoID = await fetchVideoID(completedSentence);
            console.log(videoID);
            if (videoID) {
                const currentVideoData = await fetchVideo(videoID)
                if(currentVideoData && currentVideoData.result_url) {
                    setVideo(currentVideoData.result_url)
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getIA = async(input) => {
        let chat = "Answer me " + selectedBot.condition + " with no more than 70 characters. \r"+
           "me:" + input +"\r" +
           "you:"
        
        const configuration = new Configuration({
            apiKey: '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',
          });
        const openai = new OpenAIApi(configuration);
    
        const response = await openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages: [ {"role": "system", "content": chat} ],
            temperature: 0.5,
            max_tokens: 1024,
        });
        return response.data.choices[0].message.content;
    }

    useEffect(() => {
        const fetchData = async () => {
            setMessages((prevMessages) => [...prevMessages, { text: selectedBot.greecing, sender: 'bot' }]);
            if(selectedBot.video) {
                setVideo(selectedBot.video)
            } else {
                const videoID = await fetchVideoID(selectedBot.greecing);
                console.log(videoID);
                if (videoID) {
                    const currentVideoData = await fetchVideo(videoID)
                    if(currentVideoData && currentVideoData.result_url) {
                        setVideo(currentVideoData.result_url)
                    }
                }
            }
        };

        if(greetMe) {
            fetchData();
            greetMe = false;
        }
        
    }, []);

    return (
        <div className="chat-container">
            <div className='videoAvatar'>
                <video x-webkit-airplay="allow" playsInline="" preload="yes" autoPlay className="css-1drke4h e3rlp0e0" src={video}></video>
            </div>
            <div className="Chat">
                <div className="chat-wrapper">
                    <div className="chat-messages">
                        {messages.map((message, index) => (
                            <>
                                {selectedBot && message.sender == 'bot' && (<div className="thumb bot-thumb">
                                    <span>{selectedBot.id.split('_')[0]}</span>
                                    <div className="circle-container">
                                        <img src={selectedBot.thumbnail_url} className="circle-image" />
                                    </div>
                                </div>
                                )}
                                {message.sender == 'user' && (<div className="thumb user-thumb">
                                    <img src="/assets/user.png" />
                                    <span>Me</span>
                                </div>
                                )}
                                <div key={index} className={`message ${message.sender}`}>
                                    {message.text}
                                </div>
                            </>
                        ))}
                    </div>
                    <div className="chat-input">
                        <input type="text" ref={inputRef} placeholder="Start talk with ..." />
                        <button onClick={sendMessage}>Send</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;