import React, { useState } from 'react';
import './Login.css';

function Login({setIsLoggedIn, setShowLogin, speakers}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMssg, setErrorMssg] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    setErrorMssg('')
    // Simulate API call to validate credentials (Replace with actual API call)
    if (username === 'admin' && password === 'admin') {
      setIsLoggedIn(true);
      setShowLogin(false);
      speakers.push({
        id: 'Itay Chizik',
        image_url: "https://create-images-results.d-id.com/google-oauth2%7C117120151856029768075/upl_GzhxKN2uRwge-pSxqxnU7/image.jpeg",
        thumbnail_url: "https://create-images-results.d-id.com/google-oauth2%7C117120151856029768075/upl_GzhxKN2uRwge-pSxqxnU7/image.jpeg",
        provider: "microsoft",
        voice: "en-CA-LiamNeural",
        greecing: "Hi, I am Itay, I am glad to say that 'Talk to Me' has been listed on NASDAQ",
        condition: "as CEO",
        gender: "male",
        video: "https://tokation.com/avatars/itay.mp4"

      })
    } else {
      setErrorMssg('Invalid username or password');
    }
  };

  return (
    <div className="Login">
      <div>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="username"
        />
      </div>
      <div>
        <input
          type="password"
          value={password}
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button onClick={handleLogin}>Login</button>
      <small>{errorMssg}</small>
    </div>
  );
}

export default Login;