import React from 'react';
import './Speaker.css';

function Speaker({imageUrl, speakerId, className, handleSelectSpeaker, speakerName}) {
  return (
    <>
      <div className={className + " Speaker"} onClick={handleSelectSpeaker}>
      {className.includes("selected") && <img src="/assets/v.png" className="vSign" />}
        <div class="circle-container">
          <img
              src={imageUrl}
              alt={speakerId}
              id={speakerId}
              style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
          />
        </div>
        <div className='speakerName'>{speakerName}</div>
      </div>
    </>
  );
}

export default Speaker;
