import Speaker from '../Speaker/Speaker';
import './Sidebar.css';

function Sidebar({ speakers, selectedSpeaker, onSelect }) {
  return (
    <div className="Sidebar">
      {speakers && speakers.map(speaker => (
        <div onClick={ () => onSelect(speaker) } key={speaker.id}>
        <Speaker 
          className={selectedSpeaker === speaker.id ? 'selected thumbs' : 'thumbs'}
          imageUrl={speaker.thumbnail_url} 
          speakerId={speaker.id}
          speakerName={speaker.id.split('_')[0]}
        />
      </div>
      ))}
    </div>
  );
}

export default Sidebar;
