import React, { useState, useEffect } from 'react';
import './Context.css';
import Sidebar from '../Sidebar/Sidebar';
import Login from '../Login/Login';
import Chat from '../Chat/Chat';
import WelcomeScreen from '../WelcomeScreen/WelcomeScreen';

function Context({ isLoggedIn, showLogin, setIsLoggedIn, setShowLogin }) {
  const [speakers, setSpeakers] = useState([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);
  const [isTalkToMeClicked, setIsTalkToMeClicked] = useState(false);


  const apiKey = 'dmlrYUBmaXJlLWFyYy5jb20:KVdjLG-USxCTCbGcCHkAD';

  useEffect(() => {
    // Make the API request to fetch the JSON data
    fetch('https://api.d-id.com/clips/actors', {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        for (let i = 0; i < data.actors.length; i++) {

          if(data.actors[i]["gender"] == 'male') {
            data.actors[i]["provider"] = "microsoft"
            data.actors[i]["voice"] = "en-US-DavisNeural"
            if(data.actors[i]["id"] == "matt") {
              data.actors[i]["video"] = "https://tokation.com/avatars/matt.mp4"
            } else {
              data.actors[i]["video"] = "https://tokation.com/avatars/rian.mp4"
            }
          } else {
            data.actors[i]["provider"] = "microsoft"
            data.actors[i]["voice"] = "en-US-JennyNeural"
            data.actors[i]["video"] = "https://tokation.com/avatars/amy.mp4"
          }
          
          data.actors[i]["greecing"] = `Hi, I am ${data.actors[i]['id'].split('_')[0]}, talk to me.`
          data.actors[i]["condition"] = ""
        }

        data.actors.push({
          id: 'Steve Jobs',
          image_url: "https://create-images-results.d-id.com/google-oauth2%7C117120151856029768075/drm_1SEnzv_I0nYRtOBfNb1Kt/image.png",
          thumbnail_url: "https://create-images-results.d-id.com/google-oauth2%7C117120151856029768075/drm_1SEnzv_I0nYRtOBfNb1Kt/image.png",
          provider: "elevenlabs",
          voice: "21m00Tcm4TlvDq8ikWAM",
          greecing: 'Hi, I am Steve Jobs, I choose to be a female in heaven. Ask me anything!',
          condition: "as Steve Jobs",
          gender: "male",
          video: "https://tokation.com/avatars/steve.mp4"
        })
        
        setSpeakers(data.actors)
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="Context">
      { showLogin ? (
        <Login setIsLoggedIn={setIsLoggedIn} setShowLogin={setShowLogin} speakers={speakers} />
        ) : isTalkToMeClicked ? (
          <>
            <Sidebar speakers={speakers} selectedSpeaker={selectedSpeaker} onSelect={() => null}/>
            <Chat speakers={speakers} selectedSpeaker={selectedSpeaker} />
          </>
        ) : (
          <WelcomeScreen 
            setSelectedSpeaker={setSelectedSpeaker} 
            selectedSpeaker={selectedSpeaker} 
            setIsTalkToMeClicked={setIsTalkToMeClicked}
            speakers={speakers}
          />
      )}
    </div>
  );
}

export default Context;
