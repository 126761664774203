import React from 'react';
import './WelcomeScreen.css';
import Speaker from '../Speaker/Speaker';

function WelcomeScreen({ setSelectedSpeaker, selectedSpeaker, setIsTalkToMeClicked, speakers  }) {
    const handleSelectSpeaker = (id) => {
        setSelectedSpeaker(id)
    }

    return (
        <div className="WelcomeScreen">
          <h1>Welcome to Tok2Me</h1>
          <p>Select someone to talk to</p>
            <div className="speakersList">
              {speakers && speakers.map(speaker => {
              return(
                  <Speaker 
                      className={selectedSpeaker === speaker.id ? 'selected icons' : 'icons'}
                      imageUrl={speaker.thumbnail_url} 
                      speakerId={speaker.id}
                      handleSelectSpeaker={() => handleSelectSpeaker(speaker.id)}
                      speakerName={speaker.id.split('_')[0]}
                  />
              )})}
            </div>
            <div className="talkButton" onClick={() =>{ if(selectedSpeaker) { setIsTalkToMeClicked(true) }}}>Talk to me</div>
        </div>
    );
}

export default WelcomeScreen;
