import './Header.css';

function Header({isLoggedIn, setShowLogin}) {

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  return (
    <div className="Header">
      <div className="header-logo">
        <img src="/assets/logo.png" />
        <span>TOK2ME</span>
      </div>
      { ! isLoggedIn && <div className="header-premium" onClick={ handleLoginClick }>
        <img src="/assets/crown.png" />
        <span>Get Premium</span>
      </div>}
    </div>
  );
}

export default Header;