import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Context from '../Context/Context';
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  return (
    <div className="App">
      <Header isLoggedIn={isLoggedIn} setShowLogin={setShowLogin} />
      <Context isLoggedIn={isLoggedIn} showLogin={showLogin} setIsLoggedIn={setIsLoggedIn} setShowLogin={setShowLogin} />
      <Footer />
    </div>
  );
}

export default App;
